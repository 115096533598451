// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("./../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-community-js": () => import("./../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-curriculum-js": () => import("./../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-data-protection-js": () => import("./../src/pages/data_protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-plc-intro-js": () => import("./../src/pages/plc_intro.js" /* webpackChunkName: "component---src-pages-plc-intro-js" */),
  "component---src-pages-plc-js": () => import("./../src/pages/plc.js" /* webpackChunkName: "component---src-pages-plc-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-trainings-js": () => import("./../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-pages-users-js": () => import("./../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

