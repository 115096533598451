module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MathemaTIC","short_name":"MTIC","background_color":"#2096ff","theme_color":"#2096ff","start_url":"/","icon":"src/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":730},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
